import { Counter } from "./components/Counter";
import { FetchData } from "./components/FetchData"; 
import { Home2 } from "./components/Home2";

const AppRoutes = [
  {
    index: true,
        element: <Home2 />
  },
  {
    path: '/counter',
    element: <Counter />
  },
  {
    path: '/fetch-data',
    element: <FetchData />
  }
];

export default AppRoutes;
