import React, { Component } from 'react';
import SignInHook from './Formulario';

export class Home2 extends Component { 

    render() {
        return (
            <div>
                <SignInHook> </SignInHook>
            </div>
        );
    }
}

//export default SignIn;