import React, { useEffect, useState } from 'react'; 
//import { Button, Form, FormGroup, Label, Input } from 'reactstrap';
import { useForm } from 'react-hook-form'; 
import './FormValidation.css'  
import { Collapse, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link, Route, Routes, useLocation, useNavigate } from 'react-router-dom'; 

const SignInHook = () => { 

    const { register, handleSubmit, errors } = useForm();
    
    const onSubmit = (data, err) => { 
        usuarioAPI(data); 
    };
    let navigate = useNavigate();
    const [estado, setEstado] = useState("________")
    const [dataUsuario, setDataUsuario] = useState([]);
    const [isLogin, setIsLogin] = useState(false)

    
    function validamyusuario(usdatos, apidatos) {

        if (apidatos[0]?.clave === usdatos.clave) {
            setIsLogin(true); 
            //setEstado("Usuario encontrado");
            //console.log("Si");
            //const [path,pushlocation] = useLocation();
            //pushlocation('/fetchdata');
            //console.log(location);
            //root.render(<fetchdata />);
            //this.props.history.push("/fetch-data");
            //navigate('/Home2');
            //navigate("/Home2");
        }
        else {
            setIsLogin(false);
            setEstado("Usuario no registrado");
            //console.log("No")
        }
    }

    const usuarioAPI = async (datos) => {
        try {
            const apiResultado = await fetch("api/usuarios/" + datos.usuario);
            const dts = await apiResultado.json();
            validamyusuario(datos, dts)
            setEstado(dts[0].obs);
        } catch (error) {
            console.log('Hubo un problema con la petici�n Fetch:' + error.message);
        }
    }
    useEffect(() => {
        usuarioAPI();
    }, [])
 //{isLogin ? <Redirect to="/fetch-data" /> : <Redirect to="/" />}
    return (  
        <div> 
           
        <React.Fragment> 
            <h2>Acceso</h2>
            <form onSubmit={handleSubmit(onSubmit)} className='form-react'>
                <div className='form-control'>
                    <label>Usuario</label>
                    <input type="text" name="usuario" {...register('usuario', {
                        required: {
                            value:true,
                            message:"No puede ser vacio"
                        },
                        maxLength: 15
                    })} /> 
                </div>
                <div className='form-control'>
                    <label>Clave</label>
                    <input type="password" {...register('clave', {
                        required: true,
                        maxLength: 5
                    })} /> 
                </div>
                    <button   type='submit'>Ingresar</button>
            </form> 
            <h3>{estado}</h3> 
        </React.Fragment>
        </div>
        
    )
}

export default SignInHook