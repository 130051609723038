import React from "react";
import PropTypes from "prop-types";
import { Link } from 'react-router-dom';

import "./card.css";

function Card({ imageSource, title, text }) {
    return (
        <div className="card text-center bg-dark animate__animated animate__fadeInUp">
            <div className="overflow">
                <img src={imageSource} alt="a wallpaper" className="card-img-top" />
            </div>
            <div className="card-body text-light">
                <h4 className="card-title">{title}</h4>
            </div>
            <div>
                <Link to="/counter">
                    <button type="button"> Continuar pedido</button>
                </Link>
            </div>
            
        </div>
    );
}

Card.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string, 
  imageSource: PropTypes.img
};

export default Card;
