
import React, { Component } from 'react';  
import Card from "./Card";
import '../App.css'

export class FetchData extends Component {
  static displayName = FetchData.name;

  constructor(props) {
    super(props);
    this.state = { candidatos: [], loading: true };
  }

  componentDidMount() {
    this.populateDandidatos();
  }

  static renderCandidatosTable(candidatos) {
      return ( 
          <div className="container">
               <div className="container d-flex justify-content-center align-items-center h-100"> 
                      <div className="row">
                          {candidatos.map(({ nomPlanilla, fotografia, numero }) => (
                              <div  className="col-md-4" key={numero}>
                                  <Card imageSource={fotografia} title={nomPlanilla} />
                              </div>
                          ))}
                      </div>
              </div> 
          </div>
          

    );
  }

  render() {
    let contents = this.state.loading
      ? <p><em>Loading...</em></p>
      : FetchData.renderCandidatosTable(this.state.candidatos);

    return (
      <div>
        <h1 id="tabelLabel" >Listado de candidatos</h1> 
        {contents}
      </div>
    );
  }

  async populateDandidatos() {
      const response = await fetch('api/candidatos');
    const data = await response.json();
    this.setState({ candidatos: data, loading: false });
  }
}
